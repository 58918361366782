.comment {
    padding: 10px 15px;
}

.body {
    padding-left: 54px;
    padding-top: 5px;
    font-size: 14px;
}

.content {
    & > p:last-child {
        margin-bottom: 0;
    }
}